<template lang="pug">
  .settings-view
    .kt-permissions.row.pb-4
      .st-container
        keep-alive
          router-view
</template>

<script>
import UiNavTabs from '@/components/ui/NavTabs'
import DropdownNav from '@/components/ui/DropdownNav'

export default {
  name: 'Settings',

  components: {
    UiNavTabs,
    DropdownNav
  },

  computed: {
    tabs () {
      return [
        { id: 0, title: 'ui.labels.global_settings', path: '/settings' },
        { id: 1, title: 'mail settings', path: '/mail-settings' }
      ]
    }

  }
}
</script>

<style lang="scss">
  .settings-view {
    td {
      margin: auto;
      vertical-align: middle !important;
    }
  }

</style>
<style lang="scss" scoped>
.kt-permissions {
  padding-top: 24px;
}
</style>
